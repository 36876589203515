// 存储用户token字段
export const TOKEN = 'TOKEN'
// 上次登录成功的账号信息
export const OLD_USER = 'OLD_USER'
// 运费

export const FREE_SHIPPING = [
  { label: '全部', value: '0' },
  { label: '包邮', value: '1' },
  { label: '不包邮', value: '2' },
]
// 是否正选用
export const USING = [
  { label: '全部', value: '0' },
  { label: '未被使用', value: '1' },
  { label: '使用中', value: '2' },
]
//是否已回收
export const VALIDS = [
  { label: '全部', value: '0' },
  { label: '不在回收站', value: '1' },
  { label: '已回收', value: '2' },
]

// 使用场景
export const SCENES = [
  { label: '全部', value: 0 },
  { label: 'APP产品', value: 1 },
  { label: '淘宝平台', value: 2 },
  { label: '京东平台', value: 3 },
  { label: '拼多多平台', value: 4 },
  { label: '微信小程序', value: 5 },
]

// 兑换方式
export const COUPON_TYPE = [
  { label: '全部', value: 0 },
  { label: '独立券码', value: 1 },
  { label: '重复使用券码', value: 2 },
  { label: '跳转链接', value: 3 },
]

// 文章分类
export const ARTICLE_TYPE = [
  { label: '积分运营', value: 0 },
  { label: '用户运营', value: 1 },
  { label: '燃豆产品', value: 2 },
]

// 30天销量
export const VOLUMES = [
  { label: '全部', value: 0 },
]

// 余额
export const BALANCES = [
  { label: '所有余额', value: 0 },
  { label: '余额小于7天消耗', value: 1 },
  { label: '余额小于30天消耗', value: 2 },
  { label: '余额大于30天消耗', value: 3 },
]

// 上游供应商
export const SUPPLIERS = [
  { label: '福禄直充', value: 1 },
  { label: '重庆亿奇达', value: 2 },
]

// 充值账号
export const ACCOUNT_TYPES = [
  { label: '通用充值号', value: 1 },
  { label: 'QQ相关充值号', value: 2 },
  { label: '天猫/淘宝账号', value: 3 },
]

// 商品类型
export const CATE_TYPES = [
  { label: '全部', value: 0 },
  { label: '实物商品', value: 1 },
  { label: '优惠券商品', value: 2 },
  { label: '直充商品', value: 3 },
]

export const SERVICE_TYPES = [
  { label: '全部状态', value: 0 },
  { label: '在服务期内', value: 1 },
  { label: '不在服务期', value: 2 },
]

// 商品涞源
export const PRODUCT_FROMS = [
  { label: '全部', value: 0 },
  { label: '燃豆精选商品', value: 1 },
  { label: '租户自有商品', value: 2 },
]