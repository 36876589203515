import { createRouter, createWebHistory } from 'vue-router'

export const menus = [
  {
    path: '/product',
    icon: 'AppstoreFilled',
    meta: { title: '产品及项目概况' },
    component: () => import('@/pages/routerView'),
    children: [
      { 
        path: '/product/integral',
        meta: { title: '积分俱乐部' },
        component: () => import('@/pages/product/integral'),
      }
    ],
  },
  {
    path: '/admin',
    icon: 'SmileFilled',
    meta: { title: '客户及用户概况' },
    component: () => import('@/pages/routerView'),
    children: [
      { 
        path: '/survey/customer',
        meta: { title: '客户概况' },
        component: () => import('@/pages/survey/customer'),
      },
      { 
        path: '/survey/user',
        meta: { title: '用户概况' },
        component: () => import('@/pages/survey/user'),
      }
    ],
  },
  {
    path: '/customer',
    icon: 'AccountBookFilled',
    meta: { title: '客户订购管理' },
    component: () => import('@/pages/routerView'),
    children: [
      { 
        path: '/customer/order',
        meta: { title: '订单管理' },
        component: () => import('@/pages/customer/order'),
      },
    ],
  },
  {
    path: '/finance',
    icon: 'PayCircleFilled',
    meta: { title: '财务资金管理' },
    component: () => import('@/pages/routerView'),
    children: [
      { 
        path: '/finance/core',
        meta: { title: '财务中心' },
        component: () => import('@/pages/finance/core'),
      },
      { 
        path: '/finance/recharge',
        meta: { title: '客户充值管理' },
        component: () => import('@/pages/finance/recharge'),
      },
      { 
        path: '/finance/withdraw',
        meta: { title: '客户提现管理' },
        component: () => import('@/pages/finance/withdraw'),
      },
    ],
  },
  {
    path: '/invoice',
    icon: 'ProfileFilled',
    meta: { title: '发票合同管理' },
    component: () => import('@/pages/routerView'),
    children: [
      { 
        path: '/invoice/apply',
        meta: { title: '合同申请' },
        component: () => import('@/pages/invoice/apply'),
      },
      { 
        path: '/invoice/product',
        meta: { title: '产品开票申请' },
        component: () => import('@/pages/invoice/product'),
      },
      { 
        path: '/invoice/commodity',
        meta: { title: '商品自动开票' },
        component: () => import('@/pages/invoice/commodity'),
      },
    ],
  },
  {
    path: '/commodity',
    icon: 'CalendarFilled',
    meta: { title: '燃豆严选商品库' },
    component: () => import('@/pages/routerView'),
    children: [
      { 
        path: '/commodity/coupon',
        meta: { title: '优惠券商品' },
        component: () => import('@/pages/commodity/coupon'),
      },
      { 
        path: '/commodity/goods',
        meta: { title: '实物商品' },
        component: () => import('@/pages/commodity/goods'),
      },
      { 
        path: '/commodity/recharge',
        meta: { title: '直充' },
        component: () => import('@/pages/commodity/recharge'),
      },
      { 
        path: '/commodity/template',
        meta: { title: '运费模版管理' },
        component: () => import('@/pages/commodity/template'),
      },
      { 
        path: '/commodity/address',
        meta: { title: '地址库管理' },
        component: () => import('@/pages/commodity/address'),
      },
    ],
  },
  {
    path: '/order',
    icon: 'ProfileFilled',
    meta: { title: '订单中心' },
    component: () => import('@/pages/routerView'),
    children: [
      { 
        path: '/order/core',
        meta: { title: '常规订单(仅燃豆)' },
        component: () => import('@/pages/order/core'),
      },
      { 
        path: '/order/abnormal',
        meta: { title: '异常订单' },
        component: () => import('@/pages/order/abnormal'),
      },
    ],
  },
  {
    path: '/article',
    icon: 'BookFilled',
    meta: { title: '官网文章' },
    component: () => import('@/pages/routerView'),
    children: [
      { 
        path: '/article/manual',
        meta: { title: '后台操作手册' },
        component: () => import('@/pages/article/manual'),
      },
      { 
        path: '/article/operate',
        meta: { title: '燃豆运营学院' },
        component: () => import('@/pages/article/operate'),
      },
      { 
        path: '/article/service',
        meta: { title: '服务市场' },
        component: () => import('@/pages/article/service'),
      },
    ],
  },
]

const singlePage = [
  {
    path: '/survey/customer/:id',
    meta: { title: '查看客户经营信息' },
    component: () => import('@/pages/survey/customer/detail'),
  },
  {
    path: '/customer/order/add',
    meta: { title: '手动添加订购单' },
    component: () => import('@/pages/customer/order/detail'),
  },
  {
    path: '/customer/order/:id',
    meta: { title: '调整订购单' },
    component: () => import('@/pages/customer/order/detail'),
  },
  {
    path: '/commodity/coupon/add',
    meta: { title: '发布自有优惠券商品' },
    component: () => import('@/pages/commodity/coupon/detail'),
  },
  {
    path: '/commodity/coupon/:id',
    meta: { title: '编辑自有优惠券商品' },
    component: () => import('@/pages/commodity/coupon/detail'),
  },
  {
    path: '/commodity/goods/add',
    meta: { title: '发布实物商品' },
    component: () => import('@/pages/commodity/goods/detail'),
  },
  {
    path: '/commodity/goods/:id',
    meta: { title: '编辑实物商品' },
    component: () => import('@/pages/commodity/goods/detail'),
  },
  {
    path: '/commodity/recharge/add',
    meta: { title: '发布直充商品' },
    component: () => import('@/pages/commodity/recharge/detail'),
  },
  {
    path: '/commodity/recharge/:id',
    meta: { title: '编辑直充商品' },
    component: () => import('@/pages/commodity/recharge/detail'),
  },
  {
    path: '/commodity/stock/:id',
    meta: { title: '编辑库存' },
    component: () => import('@/pages/commodity/stock'),
  },
  {
    path: '/commodity/template/add',
    meta: { title: '新增运费模版' },
    component: () => import('@/pages/commodity/template/detail'),
  },
  {
    path: '/commodity/template/:id',
    meta: { title: '编辑运费模版' },
    component: () => import('@/pages/commodity/template/detail'),
  },
  {
    path: '/order/detail/:id',
    meta: { title: '订单详情' },
    component: () => import('@/pages/order/detail'),
  },
  {
    path: '/order/tripartite',
    meta: { title: '订单详情' },
    component: () => import('@/pages/order/core/tripartite'),
  },
  {
    path: '/article/manual/detail/add',
    meta: { title: '添加文章' },
    component: () => import('@/pages/article/manual/detail'),
  },
  {
    path: '/article/manual/detail/:id',
    meta: { title: '文章编辑' },
    component: () => import('@/pages/article/manual/detail'),
  },
  {
    path: '/article/operate/detail/add',
    meta: { title: '添加文章' },
    component: () => import('@/pages/article/operate/detail'),
  },
  {
    path: '/article/operate/detail/:id',
    meta: { title: '文章编辑' },
    component: () => import('@/pages/article/operate/detail'),
  },
  {
    path: '/article/service/detail/add',
    meta: { title: '添加文章' },
    component: () => import('@/pages/article/service/detail'),
  },
  {
    path: '/article/service/detail/:id',
    meta: { title: '文章编辑' },
    component: () => import('@/pages/article/service/detail'),
  },
]

singlePage.forEach(item => {
  item.meta.single = true
})

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'Login',
      meta: { title: '登陆', notLogin: true },
      component: () => import('@/pages/login'),
    },
    {
      path: '/',
      name: 'Template',
      meta: {},
      component: () => import('@/layouts/default.vue'),
      children: [
        ...menus,
        ...singlePage,
      ],
    },
    {
      path: '/:pathMatch(.*)',
      name: 'rd_404',
      meta: { title: '未找到对应页面', notLogin: true },
      component: () => import('@/pages/404'),
    },
  ],
})

// 前置路由守卫
router.beforeEach((to, from, next) => {
  const { fullPath } = to
  const isLogin = true
  if (to.meta.notLogin || isLogin) {
    if (to.path === '/') return next(menus[0].children[0].path)
    next()
  } else {
    next({
      path: '/login',
      query: {
        redirect: fullPath,
      },
    })
  }
})

router.afterEach((route) => {
  const { title = '' } = route.meta
  document.title = title
})

export default router