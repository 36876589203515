import content from '@/main'

export function objCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function isArray(ary) {
  return Array.isArray(ary)
}

export function getLocalStorage(key) {
  let r
  try {
    if (window.localStorage) {
      r = JSON.parse(window.localStorage.getItem(key)) || ''
    }
  } catch (e) {
    return r
  }
  return r
}

export function setLocalStorage(key, value) {
  try {
    let str = ''
    str = JSON.stringify(value)
    if (window.localStorage) {
      window.localStorage.setItem(key, str)
    }
  } catch (e) {
    console.log(e)
  }
}

export function copyText(text) {
  return new Promise(resolve => {
    content.$copyText(text)
      .then(() => {
        content.$message.success('复制成功')
        resolve(true)
      })
      .catch(() => {
        content.$message.error('复制失败')
        resolve(false)
      })
  })
}

export function debounce(fn, delay) {
  let timer
  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}

// 驼峰改写
export function toHumpText(text, font) {
  const ary = text.split(font)
  return [ary[0], ...ary.slice(1).map(t => t.charAt(0).toUpperCase() + t.slice(1))].join('')
}

// 避免多次调用占用带宽
function getDebounceCallback(fn) {
  let data
  let status = false
  let callBack = []
  return function () {
    return new Promise((resolve) => {
      if (data) return resolve(objCopy(data))
      if (status) return callBack.push(resolve)
      status = true
      // promise 中不让用 await 用定时器包裹
      setTimeout(async() => {
        const res = await fn()
        data = res
        status = false
        callBack.forEach(cb => cb(data))
        resolve(objCopy(data))
      })
    })
  }
}

export const getAddress = getDebounceCallback(async() => {
  const data = await import('@/lib/address.json')
  return Array.from(data)
})

export const getSectionAddress = getDebounceCallback(async() => {
  const { data } = await content.$http.get('/admin/address/sections')
  return data
})

export const getArticleTypes = getDebounceCallback(async() => {
  const { data } = await content.$http.get('/admin/articles/cate')
  return data
})

export async function getAddressTextOfLocation(location = []) {
  let list = await getAddress()
  return location.map(id => {
    const { label, children = [] } = list.find(item => item.value === id) || {}
    list = children
    return label
  }).join('、')
}

// modal
export function openDeleteModal(params) {
  content.$modal.confirm({
    centered: true,
    maskClosable: true,
    okType: 'danger',
    ...params,
  })
}

export const downloadFile = (data, title = 'test') => {
  return new Promise((res, rej) => {
    try {
      const blob = new Blob([data], { type: 'application/msword' });
      const elink = document.createElement('a')
      if ('download' in elink) {
        elink.download = title;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
        res();
      } else {
        navigator.msSaveBlob(blob, title);
        res();
      }
    } catch (error) {
      rej(error)
    }
  })
};