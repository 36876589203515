<template>
  <component :is="icon" :style="{ color: font.color }" />
</template>

<script>
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'IconFont',
  props: {
    name: String,
    font: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      icon: icons[this.name || this.font.name],
    }
  },
}
</script>

<style>

</style>