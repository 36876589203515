import axios from 'axios'
import router from '@/router/index'
import { getLocalStorage } from './tools'
import { TOKEN } from './static'
import content from '@/main'

const PROXY_URL = {
  'admin': {
    test: 'http://api.test.randou-tech.cn/manage0905',
    prod: 'https://api.randou-tech.com/manage0905',
    sandbox: 'https://api.sandbox.randou-tech.cn/manage0905',
  },
}
export default () => {
  const REQUEST_TIMEOUT = 15 * 1000
  const axiosConfig = () => ({
    timeout: REQUEST_TIMEOUT,
    headers: {
    },
  })
  const interceptorsRequest = request => {
    request.headers.Authorization = getLocalStorage(TOKEN)
    const { url } = request
    const host = window?.location?.host || '';
    if (window.NODE_ENV !== 'development' && url.startsWith('/')) {
      const type = url.split('/')[1]
      if (PROXY_URL[type]) {
        const { test, prod, sandbox } = PROXY_URL[type]
        if (/test/.test(host)) {
          // 测试接口
          request.url = url.replace(`/${type}`, test)
        } else if (/sandbox/.test(host)) {
          // 沙箱接口
          request.url = url.replace(`/${type}`, sandbox)
        } else {
          // 正式接口
          request.url = url.replace(`/${type}`, prod)
        }
      }
    }
    return request
  }
  const interceptorsResponse = response => {
    const { data, status } = response
    if (status >= 200 && status < 300 && data.success) {
      return data
    }
    return { data, errType: 'error' }
  }
  const interceptorsCatch = error => {
    const { status, data } = error.response
    const { error: err } = data
    content.$message.error(err)
    const { fullPath } = router.currentRoute?.value || {}
    if (status === 401 && fullPath !== '/login') {
      router.replace({
        path: '/login',
        query: {
          redirect: fullPath,
        },
      })
    }
    // return { data, errType: 'broken' }
    throw Error(err)
  }

  const instance = axios.create(axiosConfig())

  instance.interceptors.request.use(interceptorsRequest, interceptorsCatch)

  instance.interceptors.response.use(interceptorsResponse, interceptorsCatch)

  return instance
}
