<template>
  <div id="rd__container--main" class="min_width">
    <a-config-provider :locale="locale" :autoInsertSpaceInButton="false">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
    }
  },
}
</script>

<style>
* {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
</style>

<style lang="scss" scoped>
#rd__container--main {
  height: calc(100vh - constant(safe-area-inset-bottom));
  height: calc(100vh - env(safe-area-inset-bottom));
  margin: 0 auto;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #F0F3F7;
}
</style>
