import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import Copy from 'vue-clipboard2'
import store from './store'
import Antd from 'ant-design-vue'
import { Modal } from 'ant-design-vue'
import IconFont from '@/components/common/IconFont'
import axios from '@/lib/axios'
import dayjs from 'dayjs'

import 'ant-design-vue/dist/antd.css';
import '@/assets/scss/index.scss'

const app = createApp(App)
const http = axios()
app.config.globalProperties.$modal = Modal
app.config.globalProperties.$http = http
app.config.globalProperties.$http.get = (url, params, options = {}) => http({ method: 'get', url, params, ...options })
app.config.globalProperties.$http.delete = (url, data, options = {}) => http({ method: 'delete', url, data, ...options })
app.config.globalProperties.formatDate = (value, format_value = 'YYYY-MM-DD') => {
  if (!value) return '-'
  value = Number(value)
  if (value < 1000000000000) value = value * 1000
  return dayjs(Number(value)).format(format_value)
}
app.config.globalProperties.format = (value, format_value = 'YYYY-MM-DD HH:mm:ss') => {
  if (!value) return '-'
  value = Number(value)
  if (value < 1000000000000) value = value * 1000
  return dayjs(Number(value)).format(format_value)
}
app.use(Antd)
app.use(Copy)
app.use(router)
app.use(store)
app.component('icon-font', IconFont)
app.mixin({
  data() {
    return {
      'common_color': '#557DFD',
    }
  },
})
app.mount('#app')

export default app.config.globalProperties